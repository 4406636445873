<div class="dashboard" style="margin-top: 10px;">
  <div class="grid p-fluid sv-form-row overview-boxes">

    <div class="col-12 md:col-4">
      <div class="overview-box overview-box-3">
        <div class="overview-box-title"><i class="ci ci-user-profile-alt"></i><span>
            Hồ sơ trẻ em hưởng lợi
          </span></div>
        <div class="overview-box-count">{{dashboardCountModel.countRecord}} {{'Hồ sơ' | translate}}</div>
      </div>
    </div>
    <div class="col-12 md:col-8"></div>
    <div class="col-12 md:col-6">
      <div class="field">
        <label>{{'FORM.STATUS' | translate}}</label>
        <p-dropdown class="sv-dropdown" [options]="arrYears" [autoDisplayFirst]="false" (onChange)="onChangeYear()"
          dropdownIcon="ci ci-chevron-down-1" appendTo="body" [(ngModel)]="year" [filter]="true" filterBy="label,value">
        </p-dropdown>
      </div>
    </div>
    <div class="col-12 md:col-6">

    </div>
    <div class="col-12 md:col-6">
      <p-chart type="bar" [data]="chartRecordCreated" [options]="stackedOptions"></p-chart>
    </div>
    <div class="col-12 md:col-6">
      <p-chart type="bar" [data]="chartRecordUpdated" [options]="stackedOptionsUpdated"></p-chart>
    </div>

  </div>
</div>
