import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { SecondPageEditBase, SvCorePublicService } from 'sv-shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends SecondPageEditBase implements OnInit {

  model = { instanceId: '', resetPasswordCode: '', newPassword: '' };

  constructor(
    protected _injector: Injector,
    private _svCorePublicService: SvCorePublicService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) {
    super(_svCorePublicService, _injector);
  }

  async ngOnInit() {
    this.formGroup = new FormGroup({
      newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });

    this.model.instanceId = this._activatedRoute.snapshot.paramMap.get('instanceId');
    this.model.resetPasswordCode = this._activatedRoute.snapshot.paramMap.get('code');
  }

  onResetPassword() {
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      this.scrollToTop();
      return;
    } else {
      if (this.model.instanceId != '' && this.model.resetPasswordCode != '' && this.model.newPassword != '') {
        this._svCorePublicService.doResetPassword(this.model).then(rs => {
          if (rs.success) {
            this._notifierService.showSuccess(this._translateService.instant("LOGIN.RESETPASSWORDOK"));
            this.goLogin();
          }
        }, error => {
          this._notifierService.showWarning(this._translateService.instant(error.error.error));
        });
      }
    }
  }
  goLogin() {
    this._router.navigate(['/authentication/signin']);
  }
  onFocus() {
    this.submitting = false;
  }

}
