<div class="sv-page-login">
  <div class="sv-page-login-content-area overflow-y-scroll">
    <div class="sv-page-login-content-area__header">
      <div class="sv-page-login-logo-accessory">
        <a class="sv-page-login-logo-wrapper" href="javascript:void(0)">
          <img src="{{environment.appMetadata.main.logo}}" class="sv-page-login-logo" />
        </a>
        <div class="sv-page-login-logo-accessory__title">{{environment.appMetadata.main.owner}}</div>
        <div class="sv-page-login-logo-accessory__slogan">{{environment.appMetadata.main.title}}</div>
      </div>
    </div>
    <div class="sv-page-login-content-area__body">
      <router-outlet></router-outlet>
    </div>

    <div class="sv-page-login-content-area__footer">
      <div class="h-cursor-pointer"><a class="sv-page-menu-item-link"
          href="https://www.ilo.org/hanoi/Whatwedo/Projects/WCMS_428372/lang--vi/index.htm" target="_blank">
          <b>Thông tin chi tiết dự án</b></a></div>
      <!-- <div class="h-cursor-pointer">{{'Trung tâm CNTT' | translate}}</div> -->
    </div>
  </div>
</div>
<p-toast class="sv-toast" position="bottom-right" key="success-warn"></p-toast>
