import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecondPageEditBase, SvCorePublicService } from 'sv-shared';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent extends SecondPageEditBase implements OnInit {
    model = { instanceId: '', resetPasswordCode: '', };

    constructor(
        protected _injector: Injector,
        private _svCorePublicService: SvCorePublicService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
    ) {
        super(null, _injector);
    }

    async ngOnInit() {
        this.model.instanceId = this._activatedRoute.snapshot.paramMap.get('instanceId');
        this.model.resetPasswordCode = this._activatedRoute.snapshot.paramMap.get('code');

        if (this.model.instanceId != '' && this.model.resetPasswordCode != '') {
            this._svCorePublicService.verifyEmail(this.model).then(rs => {
                if (rs.success) {
                    this._notifierService.showSuccess(this._translateService.instant("REGISTER.VERRIFY_OK"));
                    this._router.navigate(['/authentication/signin']);
                }
            }, error => {
                this._notifierService.showWarning(this._translateService.instant(error.error.error));
            });
        }
    }

}
