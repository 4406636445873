import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BaseService, ExportService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChildRecordService extends BaseService {
  constructor(http: HttpClient, injector: Injector
    , private _exportService: ExportService,) {
    super(http, injector, `${environment.apiDomain.svILOEndpoint}/ChildRecord`);
  }

  find(model: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/find`;
    return this._http
      .post<ResponseResult>(apiUrl, model)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

  countTotal() {
    return this.defaultGet(`${this.serviceUri}/CountTotal`);
  }

  getChildRecordWordTemplate(id: string) {
    const url = `${this.serviceUri}/GetChildRecordWordTemplate/${id}`;
    return this._http
      .post(`${url}`, null, { responseType: 'blob' as 'json' })
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
      .subscribe(res => {
        this._exportService.saveAsWordFile(res, `${id}`);
      });
  }

  exportChildRecordToWord(id: string, idGroupItem: number) {
    const url = `${this.serviceUri}/ExportChildRecordToWord/${id}/${idGroupItem}`;
    return this._http
      .post(`${url}`, null, { responseType: 'blob' as 'json' })
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
      .subscribe(res => {
        this._exportService.saveAsWordFile(res, `${id}_${idGroupItem}`);
      });
  }

  getTemplateImportExcel() {
    const url = `${this.serviceUri}/GetTemplateImportExcel`;
    return this._http
      .post(`${url}`, null, { responseType: 'blob' as 'json' })
      .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
      .subscribe(res => {
        this._exportService.saveAsExcelFile(res, 'users_import');
      });
  }

  importData(data: any): Promise<ResponseResult> {
    const apiUrl = `${this.serviceUri}/ImportData`;
    return this._http
      .post<ResponseResult>(apiUrl, data)
      .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
  }

}
