import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './config/oidc-auth.config';
import { Subject } from 'rxjs/internal/Subject';
import { AuthenticationService, UserService, CommonService } from 'sv-shared';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { PrimeNGConfig } from 'primeng/api';
import { Spinkit } from 'ng-http-loader';
import { LanguageService } from './sv-core/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {

  public spinkit = Spinkit;

  // Private
  private _unsubscribeAll: Subject<any>;
  private _sub: Subscription;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  // chế độ mở rộng trang
  pageContentExpansionModeActive: boolean;

  pageLoaded = false;

  // @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ScrollPanel;

  // index của "nhóm menu item" (menu level 0) hiện đang activated
  activatedMenuGroupIndex: number;

  isMenuClicked: boolean = false;
  dsLanguage = [];
  constructor(
    private translate: TranslateService,
    private _oauthService: OAuthService,
    private auth: AuthenticationService,
    private _userService: UserService,
    private _router: Router,
    public _commonService: CommonService,
    private primengConfig: PrimeNGConfig,
    private _languageService: LanguageService,
  ) {
    const userLang = 'vi';
    this.translate.addLangs(['vi', 'en-US']);
    if (localStorage.getItem('language') !== null) {
      this.translate.use(localStorage.getItem('language'));
    } else {
      const lang = userLang.match(/vi|en-US/) ? userLang : 'vi';
      this.translate.use(lang);
      localStorage.setItem('language', lang);
    }
    // Config for authentication
    this.configureWithNewConfigApi();
  }

  ngAfterViewInit() {
    // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
  }

  ngOnInit() {
    this.primengConfig.ripple = true;

  }

  async getLangs() {
    this.dsLanguage = [];
    await this._languageService.getsAll().then(rs => {
      if (rs.success) {
        rs.data.map(item =>
          this.dsLanguage.push(item.code)
        );
      }
    })
  }

  private configureWithNewConfigApi() {
    this._oauthService.setStorage(localStorage);
    this._oauthService.configure(authConfig);
    this._oauthService.tokenValidationHandler = new JwksValidationHandler();

    if (!this._oauthService.hasValidAccessToken()) {
      this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
        if (!rs) {
          // this._oauthService.initImplicitFlow(`${environment.clientDomain.appDomain}`);
        } else {
          this._userService.returnPromises();
          this.pageLoaded = true;
        }
      });
    } else {
      this._userService.returnPromises();
      this.pageLoaded = true;
      this._oauthService.loadDiscoveryDocument();
    }

    // Optional
    //this._oauthService.setupAutomaticSilentRefresh();
  }

  onMenuCollapseToggleClick() {
    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }
  }

  onSwitchModule(moduleCode) {
    switch (moduleCode) {
      case 0: {// administrator
        this._router.navigate(['/admin/master-data/single']);
        break;
      }
      default: { // frontend
        this._router.navigate(['/']);
        break;
      }
    }
  }

  onTopbarLogout() {
    //this.auth.logout();
    localStorage.clear();
    sessionStorage.clear();
    this._router.navigate(['/authentication/signin']);
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._sub.unsubscribe();
  }

  togglePageContentExpansionMode() {
    this.pageContentExpansionModeActive = !this.pageContentExpansionModeActive;
  }
}

