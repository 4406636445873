import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SecondPageEditBase, SvCorePublicService } from 'sv-shared';
import { LanguageService } from '../../../sv-core/services/language.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent extends SecondPageEditBase implements OnInit {
  model: ModelSignUp = new ModelSignUp();
  returnUrl: string;
  enableLogin = true;
  dsLanguage = [];
  constructor(
    public translate: TranslateService,
    protected _injector: Injector,
    private formBuilder: FormBuilder,
    private _oauthService: OAuthService,
    private _svCorePublicService: SvCorePublicService,
    private _languageService: LanguageService,
    private _router: Router,
  ) {
    super(null, _injector);
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      displayName: new FormControl('', [Validators.required]),
      passwordHash: new FormControl('', [Validators.required, Validators.minLength(8)]),
    });
  }

  async ngOnInit() {
    this.getLangs();
  }

  getLangs() {
    this._languageService.getsAll().then(rs => {
      if (rs.success) {
        this.dsLanguage = rs.data;
      }
    })
  }

  changeLang(lang: string) {
    this.translate.use(lang.match(/vi|en-US/) ? lang : 'vi');
    localStorage.setItem('language', lang);
  }

  async clickRegister() {
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      this.scrollToTop();
      return;
    } else {
      this._svCorePublicService.signIn(this.model).then(rs => {
        if (rs.success) {
          this._notifierService.showSuccess(this._translateService.instant("REGISTER.MESSACE_OK"));
        } else {

        } this._notifierService.showWarning(this._translateService.instant(rs.error));
      }, error => {
        this._notifierService.showWarning(this._translateService.instant(error.error.error));
      });
    }

  }

  onFocus() {
    this.submitting = false;
  }

  goLogin() {
    this._router.navigate(['/authentication/signin']);
  }

}


class ModelSignUp {
  email: string;
  displayName: string;
  passwordHash: string;
}
