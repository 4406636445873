<form [formGroup]="formGroup">
  <div class="sv-form-item -required"
    [ngClass]="{'-error': formGroup.get('newPassword').errors?.required || formGroup.get('newPassword').errors?.minlength}">
    <div class="sv-form-item__label">{{'LOGIN.PASSWORD' | translate}}</div>
    <div class="sv-form-item__input-wrapper -type-text-field">
      <input class="sv-form-item__input sv-text-field" type="password" (focus)="onFocus()"
        [(ngModel)]="model.newPassword" formControlName="newPassword"
        placeholder="{{'LOGIN.INPUTPASSWORD' | translate}}">
    </div>

    <div class="sv-form-item__message-container">
      <div class="sv-form-item__message">{{'FORM.REQUIRED' | translate}}</div>
    </div>
  </div>
  <div>
    <div class="sv-page-login-password-options">
      <div class="sv-page-login-password-options__forgot h-cursor-pointer" (click)="goLogin()">
        {{'LOGIN.RETURN' | translate}}
      </div>
      <div class="sv-page-login-password-options__forgot h-cursor-pointer">
        {{'LOGIN.PASSWORDVALIDATOR' | translate}}
      </div>
    </div>
    <button type="button" (click)="onResetPassword()"
      class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing-fast">
      <span class="sv-btn__label">{{'USERINFO.BTNCHAGEPASS' | translate}}</span>
      <span class="sv-btn__icon ci ci-arrow-right"></span>
    </button>
  </div>
</form>
