import { Component, OnInit } from '@angular/core';
import { SysMenuService } from 'sv-shared';

@Component({
  selector: 'app-Unauthorize',
  templateUrl: './Unauthorize.component.html',
  styleUrls: ['./Unauthorize.component.scss']
})
export class UnauthorizeComponent implements OnInit {

  constructor(
    // private _inspectMenuService: InspectMenuService,
    private _menuService: SysMenuService
  ) { }

  ngOnInit() {
    // this._inspectMenuService.getMenuItems()
    //     .then(menuItems => {
    //         this._menuService.setMenuItems(menuItems);
    //     });
  }

}
