import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs/internal/Subject';
import { BasicUserInfo, NotificationService, PermissionService, SignalRService, UserService } from 'sv-shared';
import { Subscription } from 'rxjs';

enum SidebarTabs {
  NOTIFICATION,
  ACTIVITY
}

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit, OnDestroy, AfterViewInit {
  environment = environment;
  searchKey = '';

  _unSubscribeAll = new Subject<any>();
  _sub: Subscription;
  fileApi = '';

  @ViewChild('profile')
  profileElement: ElementRef;

  @ViewChild('notification')
  notificationElement: ElementRef;

  fullName = 'Admin';
  position = 'Quản trị viên';
  avatarUrl = 'assets/images/avatar.jpg';
  currentUser = new BasicUserInfo();

  showDropdown = {
    subsystem: false,
    userSetting: false,
    rightSidebar: false
  };

  SidebarTabs = SidebarTabs;

  currentSidebarTab: SidebarTabs = SidebarTabs.NOTIFICATION;

  subsystems: any[] = [
    {
      icon: 'usp usp-integration-03',
      title: 'Quản lý NCKH',
    },
    {
      icon: 'usp usp-finance-graph',
      title: 'Quản lý Tài chính',
    },
  ];


  maxNotification = 10;
  totalUnRead = 0;
  url = '';
  urldetail = '';
  model = [];
  userId: string;
  currentItem: any;

  constructor(
    public app: AppComponent,
    private _userService: UserService,
    private _signalRService: SignalRService,
    private _notificationService: NotificationService,
    private _permissionService: PermissionService,
  ) {
    this.fileApi = '';
    if (environment.appMetadata.config.useNotification) {
      this._signalRService.initNotificationHub();
    }
  }

  ngOnInit(): void {

    this._userService.getCurrentUser().then(rs => {
      if (rs.fullName) {
        this.currentUser = rs;
        this.fullName = rs.fullName;
        this.getImageUrl();
      }
    });

    if (environment.appMetadata.config.useNotification) {
      this._userService.getId().then(rs => {
        this.userId = rs.toString();
        this._signalRService.start(
          environment.signalrConfig.topic.notificationCreated,
          'NotificationHub',
          this.notifyTrigger.bind(this)
        );

        this._signalRService.start(
          environment.signalrConfig.topic.notificationCreated,
          'CommonHub',
          this.refreshPermissions.bind(this)
        );

        this.refreshNotification();
      });
    }
  }

  getImageUrl() {
    if (this.currentUser && this.currentUser.avatar) {
      this.avatarUrl = `${this.fileApi}/file/public/${this.currentUser.avatar}/Download`;
    } else {
      this.avatarUrl = 'assets/images/avatar.jpg';
    }
  }


  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this._unSubscribeAll.next(true);
    this._unSubscribeAll.complete();
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  // Subsystem
  //-------------------

  closeSubsystemsDropdown(event) {
    this.showDropdown.subsystem = false;
  }

  toggleSubsystemsDropdown() {
    this.showDropdown.subsystem = !this.showDropdown.subsystem;
  }

  // user setting
  //-------------------

  closeUserSettingDropdown(event) {
    this.showDropdown.userSetting = false;
  }

  toggleUserSettingDropdown() {
    this.showDropdown.userSetting = !this.showDropdown.userSetting;
  }

  // Sidebar
  //-------------------

  closeRightSidebar(event) {
    this.showDropdown.rightSidebar = false;
  }

  toggleRightSidebar() {
    this.showDropdown.rightSidebar = !this.showDropdown.rightSidebar;
  }

  //#region Notifications
  refreshPermissions() {
    this._permissionService.refreshPermissions();
  }

  hasChildren() {
    if (this.model) {
      for (let i = 0; i < this.model.length; i++) {
        let item = this.model[i];

        if (item.items) {
          return true;
        }
      }
    }

    return false;
  }

  // helper
  //------------------------
  randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getRandomItemFromList(listItem) {
    let index = Math.floor(Math.random() * listItem.length);
    return listItem[index];
  }

  notifyTrigger(data: any) {
    if (data !== null) {
      if (data.userId !== null) {
        if (data.userId.indexOf(',' + this.userId + ',') !== -1) {
          console.log('refreshNotification:  ' + this.userId);
          this.refreshNotification();
        }
      } else {
        console.log('refreshNotification:  All');
        this.refreshNotification();
      }
    }
  }

  refreshNotification() {
    var model = {
      keyword: '',
      offset: 0,
      pageSize: 9999999,
      isRead: 2,
    }
    this._notificationService.getsMyNotification(model)
      .then(result => {
        if (result.success) {
          this.model = result.data;
          this.totalUnRead = result.totalRecord;
          sessionStorage.setItem('numOfNotifiUnRead', this.totalUnRead.toString());
        }

      });
  }

  readAll(event) {
    event.preventDefault();
    event.stopPropagation();

    this._notificationService.readAll().then(rs => {
      this.model.forEach(element => {
        if (element.read != null) {
          element.read.push({ userId: this.userId });
        } else {
          element.read = [{ userId: this.userId }];
        }
      });
    });

    this.totalUnRead = 0;
  }

  readItem(item) {
    item.isRead = true;
    this._notificationService.readMessage(item.id).then(rs => {
      if (rs.success)
        this.totalUnRead -= 1;
    });
  }
  //#endregion
}
