import { Component, OnInit } from '@angular/core';
import { SysLogService } from 'src/app/services/sys-log.service';
import { ChildRecordService } from 'src/app/sv-ilo/services/child-record.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  dashboardCountModel = { countRecord: 0 };
  chartRecordCreated = [];
  chartRecordUpdated = [];
  arrYears = [];
  year = (new Date()).getFullYear();

  stackedOptions = {
    tooltips: {
      mode: 'index',
      intersect: false
    },
    responsive: true,
    title: {
      display: true,
      text: 'Số hồ sơ tạo mới'
    }
  };

  stackedOptionsUpdated = {
    tooltips: {
      mode: 'index',
      intersect: false
    },
    responsive: true,
    title: {
      display: true,
      text: 'Số hồ sơ được cập nhật'
    }
  };

  constructor(
    private _childRecordService: ChildRecordService,
    private _sysLogService: SysLogService,
  ) {
    for (let i = this.year; i >= 2022; i--) {
      this.arrYears.push({ label: i, value: i });
    }
  }

  ngOnInit() {
    this.countTotal();
    this.getChartSysLogCreated();
    this.getChartSysLogUpdated();
  }

  countTotal() {
    this._childRecordService.countTotal().then(res => {
      if (res.success) {
        this.dashboardCountModel = res.data;
      }
    })
  }

  getChartSysLogCreated() {
    this._sysLogService.getChartLogDataByMonth("ChildRecord", this.year, 1).then(res => {
      if (res.success) {
        this.chartRecordCreated = res.data;
      }
    })
  }

  getChartSysLogUpdated() {
    this._sysLogService.getChartLogDataByMonth("ChildRecord", this.year, 2).then(res => {
      if (res.success) {
        this.chartRecordUpdated = res.data;
      }
    })
  }

  onChangeYear() {
    this.getChartSysLogCreated();
    this.getChartSysLogUpdated();
  }
}
