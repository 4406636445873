<div class="sv-page-login-logo-accessory__slogan" style="text-align: center;text-transform: uppercase">
  {{'LOGIN.BTNREGISTER' | translate}}
</div>
<form [formGroup]="formGroup">
  <div class="sv-form-item -required" [ngClass]="{'-error': formGroup.get('displayName').errors?.required}">
    <div class="sv-form-item__label">{{'USERINFO.DISPLAYNAME' | translate}}</div>
    <div class="sv-form-item__input-wrapper -type-text-field">
      <input class="sv-form-item__input sv-text-field" type="text" (focus)="onFocus()" [(ngModel)]="model.displayName"
        formControlName="displayName" placeholder="{{'LOGIN.INPUTPASSWORD' | translate}}">
    </div>
    <div class="sv-form-item__message-container">
      <div class="sv-form-item__message">{{'FORM.REQUIRED' | translate}}</div>
    </div>
  </div>
  <div class="sv-form-item -required"
    [ngClass]="{'-error': formGroup.get('email').errors?.required || formGroup.get('email').errors?.email}">
    <div class="sv-form-item__label">{{'LOGIN.EMAIL' | translate}}</div>
    <div class="sv-form-item__input-wrapper -type-text-field">
      <input class="sv-form-item__input sv-text-field" type="text" (focus)="onFocus()" [(ngModel)]="model.email"
        formControlName="email" placeholder="{{'LOGIN.INPUTUSERNAME' | translate}}">
    </div>

    <div class="sv-form-item__message-container">
      <div class="sv-form-item__message">{{'LOGIN.EMAILREQUIRED' | translate}}</div>
    </div>
  </div>
  <div class="sv-form-item -required" [ngClass]="{'-error': formGroup.get('passwordHash').errors?.required}">
    <div class="sv-form-item__label">{{'LOGIN.PASSWORD' | translate}}</div>
    <div class="sv-form-item__input-wrapper -type-text-field">
      <input class="sv-form-item__input sv-text-field" type="password" (focus)="onFocus()"
        [(ngModel)]="model.passwordHash" (keyup.enter)="clickRegister()" formControlName="passwordHash"
        placeholder="{{'LOGIN.INPUTPASSWORD' | translate}}">
    </div>
    <div class="sv-form-item__message-container">
      <div class="sv-form-item__message">{{'FORM.REQUIRED' | translate}}</div>
    </div>
  </div>
  <div>
    <div class="sv-page-login-password-options">
      <div class="sv-page-login-password-options__forgot h-cursor-pointer" (click)="goLogin()">
        {{'LOGIN.BTNLOGIN' | translate}}
      </div>
      <button type="button" (click)="clickRegister()"
        class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
        <span class="sv-btn__label">{{'LOGIN.BTNREGISTER' | translate}}</span>
        <span class="sv-btn__icon ci ci-arrow-right"></span>
      </button>
    </div>
  </div>
</form>
