<ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
    <li class="sv-page-menu-item" [ngClass]="[
        isActive(i, child) ? '-activated' : '',
        isNumber(level) ? 'sv-page-menu-item--level-' + level : '',
        child.items && (!child.routerLink || child.routerLink === '#') ? '-has-submenu' : '',
        child.id ? '-menu-id-' + child.id : ''
    ]" [authorize]="child.permissions" *ngIf="child.visible !== false" [hidden]="child.status !== 1">

        <a (click)="itemClick($event,child,i,level)" class="sv-page-menu-item-link"
            *ngIf="!child.routerLink || child.routerLink === '#'" [attr.tabindex]="!visible ? '-1' : null"
            [attr.target]="child.target">
            <i class="sv-page-menu-item-link__main-icon" [ngClass]="child.icon" *ngIf="level === 1"></i>

            <span class="sv-page-menu-item-link__label">{{child.code | translate}}</span>

            <i class="ci ci-chevron-right-1 sv-page-menu-item-link__toggle-icon" *ngIf="level > 0"></i>
        </a>

        <a (click)="itemClick($event,child,i,level)" class="sv-page-menu-item-link"
            *ngIf="child.routerLink && child.routerLink !== '#'" [routerLink]="child.routerLink"
            [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
            <i class="sv-page-menu-item-link__main-icon" [ngClass]="child.icon" *ngIf="level === 1"></i>

            <span class="sv-page-menu-item-link__label">{{child.code | translate}}</span>

            <i class="ci ci-chevron-right-1 sv-page-menu-item-link__toggle-icon" *ngIf="level > 0"></i>
        </a>

        <ul app-submenu [item]="child" [level]="level + 1" *ngIf="child.items && level < 3" class="sv-page-submenu"
            [ngClass]="[
                'sv-page-submenu--level-' + (level + 1)
            ]" [hidden]="child.routerLink && child.routerLink !== '#'" [visible]="isActive(i, child)" [reset]="reset"
            [parentActive]="isActive(i, child)" [groupIndex]="root ? i : groupIndex"
            [@children]="level > 0 ? (isActive(i, child) ? 'visibleAnimated' : 'hiddenAnimated') : 'visible'">
        </ul>
    </li>
</ng-template>
