import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AppModule } from './app.module';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { RegisterComponent } from './pages/authentication/register/register.component';
import { ResetPasswordComponent } from './pages/authentication/reset-password/reset-password.component';
import { VerifyEmailComponent } from './pages/authentication/verify-email/verify-email.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ErrorPageNotFoundComponent } from './pages/not-found/not-found.component';
import { UnauthorizeComponent } from './pages/Unauthorize/Unauthorize.component';
import { AuthGuardLoginService } from './services/auth-guard-login.service';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '', component: DefaultLayoutComponent,
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'core', loadChildren: () => import('./sv-core/sv-core.module').then(m => m.SvCoreModule)
      },
      {
        path: 'dbmr', loadChildren: () => import('./sv-ilo/sv-ilo.module').then(m => m.SvIloModule)
      },
      {
        path: 'error/404',
        component: ErrorPageNotFoundComponent
      }
    ],
    canActivate: [AuthGuardService]
  },
  {
    path: 'authentication',
    component: LoginLayoutComponent,
    children: [
      { path: 'signin', component: LoginComponent },
      { path: 'reset-password/:instanceId/:code', component: ResetPasswordComponent },
      { path: 'signup', component: RegisterComponent },
      { path: 'verify/:instanceId/:code', component: VerifyEmailComponent }
    ],
    canActivate: [AuthGuardLoginService]
  },
  {
    path: 'unauthorize',
    component: UnauthorizeComponent
  },
  {
    path: '**',
    redirectTo: 'error/404'
  }
];


export const AppRoutes: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy });
