import { Component, Injector, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppComponent } from '../../../app.component';
import { SysMenuService, NotifierService } from 'sv-shared';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-menu',
  template: `
        <ul app-submenu [item]="model" [level]="0" [root]="true" class="sv-page-menu"
            [reset]="reset" [visible]="true" [parentActive]="true"></ul>
    `
})
export class AppMenuComponent implements OnInit {

  @Input() reset: boolean;

  private _unsubscribeAll = new Subject<any>();

  model: any[];

  private _menuService: SysMenuService;
  private _translateService: TranslateService;
  private _notifierService: NotifierService;

  constructor(public app: AppComponent, private _injector: Injector) {
    this._notifierService = this._injector.get(NotifierService);
    this._translateService = this._injector.get(TranslateService);
    this._menuService = this._injector.get(SysMenuService);
  }

  async ngOnInit() {
    await this.getMenu();
  }

  async getMenu() {
    const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idSystem.toString());
    if (cacheVal !== undefined && cacheVal !== null) {
      this.model = await JSON.parse(cacheVal);
    } else {
      await this._menuService.getTreeTableByIdParent(environment.clientDomain.idSystem, 0).then(rs => {
        if (rs.success) {
          this.model = rs.data;
          sessionStorage.setItem('htMenu' + environment.clientDomain.idSystem.toString(), JSON.stringify(rs.data));
        }
      });
    }
  }
}

@Component({
  selector: '[app-submenu]',
  templateUrl: './app.submenu.component.html',
  animations: [
    trigger('children', [
      state('hiddenAnimated', style({
        height: '0px'
      })),
      state('visibleAnimated', style({
        height: '*'
      })),
      state('visible', style({
        display: 'block'
      })),
      state('hidden', style({
        display: 'none'
      })),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})

export class AppSubMenuComponent {

  @Input() item: any;

  @Input() root: boolean;

  @Input() visible: boolean;

  @Input() parentItem: MenuItem;

  // index trong vòng for của nhóm menu item (menu level 0)
  @Input() groupIndex: number;

  _reset: boolean;

  _parentActive: boolean;

  activeIndex: number;

  isFirstLoad = true;

  @Input() level: number;

  constructor(public app: AppComponent, private _router: Router) { }

  itemClick(event: Event, item: MenuItem, index: number, level: number) {
    if (level === 0) {
      return;
    }

    if (item.routerLink.indexOf('http') > -1) {
      window.open(item.routerLink, '_blank');
      return;
    }

    this.isFirstLoad = false;

    this.app.isMenuClicked = true;

    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    if (this.app.activatedMenuGroupIndex !== this.groupIndex) {
      this.activeIndex = index;
    } else {
      this.activeIndex = (this.activeIndex === index) ? null : index;
    }

    this.app.activatedMenuGroupIndex = this.groupIndex;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      // setTimeout(() => {
      //     this.app.layoutMenuScrollerViewChild.moveBar();
      // }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
    }

    if (item.url || item.routerLink) {
      this.activeIndex = null;
      this.isFirstLoad = true;
    }
  }

  isActive(index: number, currentItem: any): boolean {
    if (this.app.isMenuClicked && this.app.activatedMenuGroupIndex !== this.groupIndex) {
      return false;
    }

    if (!this.isFirstLoad) {
      return this.activeIndex == index;
    }

    if (currentItem && currentItem.routerLink) {
      if (this.isSameWithCurrentPageURL(currentItem.routerLink)) {
        this.activeIndex = index;
        return true;
      }
    }

    if (currentItem && currentItem.items) {
      for (let i in currentItem.items) {
        let childItem = currentItem.items[i];

        if (this.isActive(index, childItem)) {
          return true;
        }
      }
    }

    return false;
  }

  private isSameWithCurrentPageURL(routerLink): boolean {
    const currentUrl = this._router.url;

    if (routerLink === currentUrl) {
      return true;
    }

    let currentUrlWithoutParam = currentUrl;

    if (currentUrlWithoutParam.includes('?')) {
      currentUrlWithoutParam = currentUrlWithoutParam.substr(0, currentUrlWithoutParam.indexOf('?'));
    }

    currentUrlWithoutParam = currentUrlWithoutParam.replace(/\d+/g, (str) => '--');

    return currentUrlWithoutParam === routerLink;
  }

  isNumber(element: any): boolean {
    return !isNaN(element);
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}

