<div class="sv-topbar">
    <div class="sv-topbar__content-container">
        <div class="sv-topbar__left-content">
            <div class="sv-topbar__item">
                <div class="sv-topbar-btn sv-topbar-menu-collapse-toggle -activated"
                    (click)="app.onMenuCollapseToggleClick()">
                    <span class="sv-topbar-btn__icon -state-active ci ci-close"></span>

                    <span class="sv-topbar-btn__icon -state-inactive ci ci-app-switcher"></span>
                </div>
            </div>

            <a class="sv-topbar__item sv-topbar-logo-wrapper" href="javascript:" (click)="app.onSwitchModule(1)">
                <img src="{{environment.appMetadata.main.logo2}}" class="sv-topbar-logo" />
            </a>

            <div class="sv-topbar__item sv-topbar-info">
                <div class="sv-topbar-info__subsystem-name" innerHTML="{{'Topbar.Title' | translate}}">
                </div>
            </div>
        </div>

        <div class="sv-topbar__right-content">
            <!-- <div class="sv-topbar__item">
                <div class="sv-topbar-btn">
                    <span class="sv-topbar-btn__icon ci ci-help"></span>
                </div>
            </div>

            <div class="sv-topbar__item">
                <div class="sv-topbar-btn">
                    <span class="sv-topbar-btn__icon ci ci-settings"></span>
                </div>
            </div>

            <div class="sv-topbar__item sv-custom-overlay-panel-wrapper"
                (clickOutside)="closeSubsystemsDropdown($event)">
                <div class="sv-topbar-btn" (click)="toggleSubsystemsDropdown()"
                    [class.-activated]="showDropdown.subsystem">
                    <span class="sv-topbar-btn__icon ci ci-grid"></span>
                </div>

                <div class="sv-custom-overlay-panel -bottom-left sv-topbar-subsystems-overlay"
                    [hidden]="!showDropdown.subsystem">
                    <div class="sv-topbar-subsystems-item" *ngFor="let item of subsystems">
                        <div class="sv-topbar-subsystems-item__icon" [ngClass]="item.icon"></div>

                        <div class="sv-topbar-subsystems-item__subtitle">Phân hệ</div>

                        <div class="sv-topbar-subsystems-item__title">{{item.title}}</div>
                    </div>

                    <div class="sv-topbar-subsystems-item -placeholder" *ngIf="subsystems.length % 2"></div>
                </div>
            </div> -->

            <div class="sv-topbar__item sv-custom-overlay-panel-wrapper"
                (clickOutside)="closeUserSettingDropdown($event)">
                <div class="sv-topbar-btn" (click)="toggleUserSettingDropdown()"
                    [class.-activated]="showDropdown.userSetting">
                    <span class="sv-topbar-btn__icon ci ci-user-avatar"></span>
                </div>

                <div class="sv-custom-overlay-panel -bottom-left sv-topbar-user-setting-overlay"
                    [hidden]="!showDropdown.userSetting">
                    <div class="sv-topbar-user-info-wrapper">
                        <div class="sv-topbar-user-info">
                            <img class="sv-topbar-user-info__avatar" src="{{avatarUrl}}" alt="avatar" />

                            <div class="sv-topbar-user-info__name">{{fullName}}</div>

                            <div class="sv-topbar-user-info__role">{{position}}</div>
                        </div>
                    </div>

                    <div class="sv-topbar-user-setting-action-menu">
                        <a [routerLink]="['/core/myprofile']" routerLinkActive="router-link-active"
                            class="sv-topbar-user-setting-action-item">
                            <i class="sv-topbar-user-setting-action-item__icon ci ci-user-admin"></i>
                            <span class="sv-topbar-user-setting-action-item__title">{{'USERINFO.MNU_INFO' |
                                translate}}</span>
                        </a>

                        <a [routerLink]="['/core/notifications']" class="sv-topbar-user-setting-action-item">
                            <i class="sv-topbar-user-setting-action-item__icon ci ci-notification"></i>
                            <span class="sv-topbar-user-setting-action-item__title">{{'USERINFO.NOTIFICATIONS' |
                                translate}}</span>
                        </a>

                        <a href="javascript:void(0);" class="sv-topbar-user-setting-action-item"
                            (click)="app.onTopbarLogout()">
                            <i class="sv-topbar-user-setting-action-item__icon ci ci-power"></i>
                            <span class="sv-topbar-user-setting-action-item__title">{{'USERINFO.MNU_LOGOUT' |
                                translate}}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="sv-topbar__item" (clickOutside)="closeRightSidebar($event)">
                <div class="sv-topbar-btn" (click)="toggleRightSidebar()" [class.-highlight]="totalUnRead > 0">
                    <span class="sv-topbar-btn__icon ci ci-event-schedule">
                    </span>
                </div>

                <ng-container *ngTemplateOutlet="rightSideBar"></ng-container>
            </div>
        </div>
    </div>
    <!-- <app-spinner-overlay></app-spinner-overlay> -->
</div>

<ng-template #rightSideBar>
    <div class="sv-right-sidebar-area" [ngClass]="{
                    '-show': showDropdown.rightSidebar,
                    '-hide': !showDropdown.rightSidebar
                    }">
        <div class="sv-right-sidebar-tabs-container">
            <div class="sv-right-sidebar-tab-item" [class.-activated]="currentSidebarTab === SidebarTabs.NOTIFICATION"
                (click)="currentSidebarTab = SidebarTabs.NOTIFICATION">
                {{'SidebarTabs.NOTIFICATION' | translate}}
            </div>

            <div class="sv-right-sidebar-tab-item" [class.-activated]="currentSidebarTab === SidebarTabs.ACTIVITY"
                (click)="currentSidebarTab = SidebarTabs.ACTIVITY">
                {{'SidebarTabs.ACTIVITY' | translate}}
            </div>
        </div>

        <div class="sv-right-sidebar-panel-container">
            <div class="sv-right-sidebar-panel -notification" [hidden]="currentSidebarTab !== SidebarTabs.NOTIFICATION">
                <div class="sv-notification-container">
                    <ng-template ngFor let-group [ngForOf]="model" *ngIf="hasChildren()">
                        <div class="sv-notification-group-label">{{group.label}}</div>

                        <ng-template ngFor let-item [ngForOf]="group.items">
                            <div class="sv-notification-item" [class.-unseen]="item.isRead != 1">
                                <div class="sv-notification-item__icon ci" [ngClass]="{
                                        'ci-chat': item.typeId === 1,
                                        'ci-bookmark': item.typeId === 2,
                                        'ci-edit': item.typeId === 3,
                                        'ci-notification': item.typeId === 4
                                    }" (click)="readItem(item)"></div>

                                <div class="sv-notification-item__title">{{item.title}}</div>

                                <div class="sv-notification-item__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
                            </div>
                        </ng-template>
                    </ng-template>

                    <ng-template ngFor let-item [ngForOf]="model" *ngIf="!hasChildren()">
                        <div class="sv-notification-item" [class.-unseen]="item.isRead != 1">
                            <div class="sv-notification-item__icon ci" [ngClass]="{
                                        'ci-chat': item.typeId === 1,
                                        'ci-bookmark': item.typeId === 2,
                                        'ci-edit': item.typeId === 3,
                                        'ci-notification': item.typeId === 4
                                    }" (click)="readItem(item)"></div>

                            <div class="sv-notification-item__title">{{item.title}}</div>

                            <div class="sv-notification-item__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
                        </div>
                    </ng-template>
                </div>

            </div>

            <div class="sv-right-sidebar-panel -activity" [hidden]="currentSidebarTab !== SidebarTabs.ACTIVITY">
                <div class="h-py-8 h-px-16">
                    {{'SidebarTabs.NOTIFICATIONS' | translate}}
                </div>
            </div>
        </div>
    </div>
</ng-template>
