<form [formGroup]="formGroup">
  <div class="grid p-fluid">
    <div class="col-12 md:col-12">
      <div class="field">
        <label>{{'LOGIN.EMAIL' | translate}}</label>
        <input pInputText type="text" (focus)="onFocus()" [(ngModel)]="model.email" formControlName="email"
          autocomplete="username" placeholder="{{'LOGIN.INPUTUSERNAME' | translate}}">
      </div>
    </div>
    <div class="col-12 md:col-12" *ngIf="enableLogin">
      <div class="field">
        <label>{{'LOGIN.PASSWORD' | translate}}</label>
        <span class="p-input-icon-right">
          <i class="ci ci-view cursor-pointer" (click)="onChangInputType()"></i>
          <input pInputText [type]="inputType" (focus)="onFocus()" [(ngModel)]="model.password"
            autocomplete="current-password" (keyup.enter)="clickLogin()" formControlName="password"
            placeholder="{{'LOGIN.INPUTPASSWORD' | translate}}">
        </span>

      </div>
      <div class="sv-page-login-password-options">
        <p-checkbox [(ngModel)]="model.remember" formControlName="remember" label="{{'LOGIN.REMEMBER' | translate}}"
          class="sv-checkbox sv-page-login-password-options__remember"></p-checkbox>

        <div class="sv-page-login-password-options__forgot h-cursor-pointer" (click)="goForgotPass()">
          {{'LOGIN.FORGOTPASSWORD' | translate}}
        </div>
      </div>
      <div class="sv-page-login-password-options">
        <!-- <button type="button" (click)="goRegister()"
                  class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active h-easing--flash">
                  <span class="sv-btn__label">{{'LOGIN.BTNREGISTER' | translate}}</span>
                  <span class="sv-btn__icon ci ci-add"></span>
              </button> -->
        <div class="sv-page-login-password-options__forgot h-cursor-pointer">
          <button pButton type="button" label="{{'LOGIN.BTNLOGIN' | translate}}" icon="ci ci-arrow-right"
            iconPos="right" (click)="clickLogin()">
          </button>
        </div>
      </div>
    </div>

  </div>


  <div *ngIf="!enableLogin">
    <div class="sv-page-login-password-options">
      <div class="sv-page-login-password-options__forgot h-cursor-pointer" (click)="goForgotPass()">
        {{'LOGIN.RETURN' | translate}}
      </div>
      <button pButton type="button" label="{{'LOGIN.RESETPASS' | translate}}" icon="ci ci-arrow-right" iconPos="right"
        (click)="onResetPassword()">
      </button>
    </div>
  </div>
</form>
