import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LanguageService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/language`);
    }

    GetsByTrangThai(trangThai: number) {
        const queryString = `${this.serviceUri}/GetsByTrangThai/${trangThai}`;
        return this.defaultGet(queryString);
    }
}
