import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';

import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SysLogService extends BaseService {
  constructor(http: HttpClient, injector: Injector) {
    super(http, injector, `${environment.apiDomain.sysLogEndPoint}/syslog`);
  }


  getChartLogDataByMonth(tableName: string, year: number, actionId: number) {
    const apiUrl = `${this.serviceUri}/GetChartLogDataByMonth/${year}/${actionId}?tableName=${tableName}`;
    return this.defaultGet(apiUrl);
  }
}
