import { Injectable } from '@angular/core';

@Injectable()
export class GlobalService {

  private _state = true;

  constructor() { }

  setSearchBoxState(state) {
    this._state = state;
  }

  getSearchBoxState() {
    return this._state;
  }
}
