import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageEditBase, SvCorePublicService } from 'sv-shared';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../sv-core/services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends SecondPageEditBase implements OnInit {
  model: ModelDangNhap = new ModelDangNhap();
  returnUrl: string;
  enableLogin = true;
  dsLanguage = [];

  inputType = 'password';

  constructor(
    public translate: TranslateService,
    protected _injector: Injector,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _oauthService: OAuthService,
    private _svCorePublicService: SvCorePublicService,
    private _languageService: LanguageService,
  ) {
    super(null, _injector);
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required]),
      remember: new FormControl(''),
      password: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this.getLangs();
    this.returnUrl = this._activatedRoute.queryParams['returnUrl'] || '/';
    this._oauthService.requireHttps = false;
    if (this._authenticationService.isLoggedIn() && this._oauthService.hasValidAccessToken()) {
      top.location.href = this.returnUrl;
    } else {
      localStorage.clear();
      sessionStorage.clear();
    }
  }

  getLangs() {
    this._languageService.getsAll().then(rs => {
      if (rs.success) {
        this.dsLanguage = rs.data;
      }
    })
  }

  changeLang(lang: string) {
    this.translate.use(lang.match(/vi|en-US/) ? lang : 'vi');
    localStorage.setItem('language', lang);
  }

  async clickLogin() {
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      this.scrollToTop();
      return;
    }
    await this._oauthService.loadDiscoveryDocumentAndTryLogin().then(rs => {
      if (!rs) {
        this._oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(this.model.email, this.model.password).then(result => {
          console.log(result);

          this._oauthService.setupAutomaticSilentRefresh();
          // this.authorizeService.savePermission(result);

          const currentUser = this._authenticationService.getCurrentUser();
          this._notifierService.showSuccess(this._translateService.instant('LOGIN.MESSAGE_OK'));
          setTimeout(() => {
            this.submitting = false;
            top.location.href = this.returnUrl;
          }, 500);

        },
          error => {
            //console.log(error.error.error_description);
            this._notifierService.showWarning(this.translate.instant(error.error.error_description));
            setTimeout(() => {
              this.submitting = false;
            }, 500);
          }
        );
      }
    });
  }

  onFocus() {
    this.submitting = false;
  }
  goForgotPass() {
    this.enableLogin = !this.enableLogin;
  }

  onResetPassword() {
    if (this.model.email !== undefined) {
      this.model.username = this.model.email
      this._svCorePublicService.resetPassword(this.model).then(rs => {
        if (rs.success) {
          this._notifierService.showSuccess(this._translateService.instant("LOGIN.MESSAGE_SENDPASSOK"));
        } else {
          this._notifierService.showWarning(rs.message);
        }
      }, error => {
        this._notifierService.showWarning(this._translateService.instant(error.error.error));
      });
    }

  }
  goRegister() {
    this._router.navigate(['/authentication/signup']);
  }

  onChangInputType() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }

}


class ModelDangNhap {
  username: string;
  password: string;
  remember: boolean;
  email: string;
}
