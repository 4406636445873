import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClickOutsideModule } from "ng-click-outside";
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { AuthenticationService, MultiTranslateJsonLoader, SendAccessTokenInterceptor, SvCorePublicService, SvSharedModule, UserFormatPipe, UserService } from 'sv-shared';
import { ToastModule } from 'primeng/toast';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ChartModule } from 'primeng/chart';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalService } from './services/global.service';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { QuicklinkModule } from 'ngx-quicklink';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { AppNotificationComponent } from './layouts/components/notification/app.notification.component';
import { AppMenuSearchBoxComponent } from './layouts/components/menu-search-box/app.menu-search-box.component';
import { AppMenuComponent, AppSubMenuComponent } from './layouts/components/menu/app.menu.component';
import { AppTopBarComponent } from './layouts/components/topbar/app.topbar.component';
import { AppFooterComponent } from './layouts/components/footer/app.footer.component';
import { AppProfileComponent } from './layouts/components/profile/app.profile.component';
import { UnauthorizeComponent } from './pages/Unauthorize/Unauthorize.component';
import { ErrorPageNotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/authentication/login/login.component';
import { ResetPasswordComponent } from './pages/authentication/reset-password/reset-password.component';
import { RegisterComponent } from './pages/authentication/register/register.component';
import { VerifyEmailComponent } from './pages/authentication/verify-email/verify-email.component';

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateJsonLoader(http,
    [
      { prefix: './assets/i18n/', suffix: '.json' },
      { prefix: './assets/i18n/shared/', suffix: '.json' }
    ]
  );
}



@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutes,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      defaultLanguage: 'vi',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, SvCorePublicService]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiDomain.gateway],
        sendAccessToken: false
      }
    }),
    SvSharedModule.forRoot({ environment: environment }),
    ToastModule,
    QuicklinkModule,
    NgHttpLoaderModule.forRoot(),
    ChartModule,
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginLayoutComponent,
    DefaultLayoutComponent,
    AppNotificationComponent,
    AppMenuSearchBoxComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    AppTopBarComponent,
    AppFooterComponent,
    AppProfileComponent,
    UnauthorizeComponent,
    ErrorPageNotFoundComponent,
    LoginComponent,
    DashboardComponent,
    ResetPasswordComponent,
    RegisterComponent,
    VerifyEmailComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SendAccessTokenInterceptor,
      multi: true
    },
    AuthenticationService,
    AuthGuardService,
    AuthGuardLoginService,
    MessageService,
    ConfirmationService,
    DatePipe,
    UserFormatPipe,
    UserService,
    GlobalService,
    TranslateService,
    TranslatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
