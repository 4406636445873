import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../app.component';
import { Router } from "@angular/router";
import { SysMenuService } from 'sv-shared';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-menu-search-box',
  templateUrl: 'app.menu-search-box.component.html'
})
export class AppMenuSearchBoxComponent implements OnInit {

  menuSearchResult: any[];
  menuSearchInput: string;
  arrMenu = [];
  isMenuSearchInputFocus: boolean = false;

  constructor(
    public app: AppComponent,
    private _router: Router,
    private _menuService: SysMenuService
  ) {

  }

  ngOnInit(): void {
    this.getMenu();
  }

  async getMenu() {
    const cacheVal = sessionStorage.getItem('htMenu' + environment.clientDomain.idSystem.toString());
    if (cacheVal !== undefined && cacheVal !== null) {
      this.arrMenu = await JSON.parse(cacheVal);
    } else {
      await this._menuService.getTreeTableByIdParent(environment.clientDomain.idSystem, 0).then(rs => {
        if (rs.success) {
          this.arrMenu = rs.data;
          sessionStorage.setItem('htMenu' + environment.clientDomain.idSystem.toString(), JSON.stringify(rs.data));
        }
      });
    }
  }

  onMenuSearch(event) {
    this.menuSearchResult = [];

    if (!event || !event.query) {
      return;
    }

    this.menuSearchResult = this.getMenuSearchResult(event.query);
  }

  getMenuSearchResult(keyword) {
    let result = [];

    this.regroupMenuSearchResult(keyword, this.arrMenu, result);

    return result;
  }

  private regroupMenuSearchResult(keyword: string, listToGet: any[], listToPush: any[]) {
    listToGet.forEach(item => {
      if (item.items && item.items.length) {
        this.regroupMenuSearchResult(keyword, item.items, listToPush);
      }

      if (item.status === 1
        && (item.label.toUpperCase().indexOf(keyword.toUpperCase()) >= 0)) {
        listToPush.push(item);
      }
    });
  }

  onSelectMenuSuggestion(menuItemObj) {
    this._router.navigateByUrl(menuItemObj.routerLink);
    this.menuSearchInput = '';
  }

  onBlurMenuSearchInput(event) {
    this.isMenuSearchInputFocus = false;
    this.menuSearchInput = '';
  }

  onFocusMenuSearchInput(event) {
    this.isMenuSearchInputFocus = true;
  }
}
